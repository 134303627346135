<template>
	<div class="content">
		<indexTop title="在线答题" :show="true"></indexTop>
		<div class="content-table">
			<div class="content-table-nav flex-center flex-midden">
				<div @click="toexam">考卷列表</div>
				<div class="active">我的考卷</div>
			</div>
			<div class="content-table-search">
				<div class="content-table-search-value flex-start flex-center flex-wrap">
					<div class="content-table-search-value-item flex-start flex-center">
						<div class="content-table-search-value-title">试卷名称</div>
						<el-input type="text" v-model="search.name" placeholder="请输入"></el-input>
					</div>
					<div class="content-table-search-value-item flex-start flex-center">
						<div class="content-table-search-value-title">科目</div>
						<el-select v-model="search.subjectId" placeholder="请选择科目" clearable>
							<el-option v-for="item in subject" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="content-table-search-value-item flex-start flex-center">
						<div class="content-table-search-value-title">考试局</div>
						<el-select v-model="search.officeId" placeholder="请选择考试局" clearable>
							<el-option v-for="item in examOffice" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="content-table-search-value-item flex-start flex-center">
						<div class="content-table-search-value-title">考试类型</div>
						<el-select v-model="search.paperType" placeholder="请选择考试类型" clearable>
							<el-option v-for="item in paperType" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="content-table-search-value-item flex-start flex-center">
						<div class="content-table-search-value-title">题目类型</div>
						<el-select v-model="search.questionType" placeholder="请选择题目类型" clearable>
							<el-option v-for="item in questionType" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="content-table-search-value-item flex-start flex-center">
						<div class="content-table-search-value-title">积分</div>
						<el-select v-model="search.integral" placeholder="请选择积分" clearable>
							<el-option label="免费" :value="0"></el-option>
							<el-option label="收费" :value="1"></el-option>
						</el-select>
					</div>
					<div class="content-table-search-value-item flex-start flex-center">
						<div class="content-table-search-value-title">密码</div>
						<el-select v-model="search.isPassword" placeholder="请选择密码" clearable>
							<el-option label="公开" :value="0"></el-option>
							<el-option label="私密" :value="1"></el-option>
						</el-select>
					</div>
					<div class="content-table-search-value-item flex-start flex-center">
						<div class="content-table-search-value-title">试卷状态</div>
						<el-select v-model="search.answerStatus" placeholder="请选择试卷状态" clearable>
							<el-option label="未作答" :value="0"></el-option>
							<el-option label="已判卷" :value="1"></el-option>
							<el-option label="未判卷" :value="2"></el-option>
							<el-option label="自我判卷" :value="3"></el-option>
							<el-option label="判卷审核中" :value="4"></el-option>
							<el-option label="判卷中" :value="5"></el-option>
						</el-select>
					</div>
					<div class="content-table-search-value-item flex-start flex-center">
						<div class="content-table-search-value-title">创建时间</div>
						<el-date-picker v-model="search.date" size="small" style="width: 100%;" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
						</el-date-picker>
					</div>
					<div class="content-table-search-value-button flex-start flex-center">
						<div @click="reset">重置</div>
						<div @click="getTopList()">搜索</div>
					</div>
				</div>
			</div>
			
			 <el-table :data="tableData" style="width: 100%">
				<el-table-column prop="name" label="试卷名称"></el-table-column>
				<el-table-column prop="questionTypeName" label="题目类型" width="100"></el-table-column>
				<el-table-column prop="officeName" label="考试局" width="150" show-overflow-tooltip></el-table-column>
				<el-table-column prop="subjectName" label="状态" width="100">
					<template slot-scope="scope">
						<div v-if="scope.row.answerStatus == 0">未作答</div>
						<div v-if="scope.row.answerStatus == 1">己判卷</div>
						<div v-if="scope.row.answerStatus == 2">未判卷</div>
						<div v-if="scope.row.answerStatus == 3">自我判卷</div>
						<div v-if="scope.row.answerStatus == 4">判卷审核中</div>
						<div v-if="scope.row.answerStatus == 5">判卷中</div>
					</template>
				</el-table-column>
				<el-table-column prop="answerScore" label="得分" width="100"></el-table-column>
				<el-table-column prop="subjectName" label="科目" width="100" show-overflow-tooltip></el-table-column>
				<el-table-column prop="paperTypeName" label="考卷类型" width="100">
					
				</el-table-column>
				<el-table-column prop="isPassword" label="密码" width="100">
					<template slot-scope="scope">
						<div v-if="scope.row.isPassword == 0">公开</div>
						<div v-if="scope.row.isPassword == 1">私密</div>
					</template>
				</el-table-column>
				<el-table-column prop="integral" label="积分" width="100">
					<template slot-scope="scope">
						<div v-if="scope.row.integral <= 0">免费</div>
						<div v-else>{{scope.row.integral}}积分</div>
					</template>
				</el-table-column>
				<el-table-column prop="date" label="操作" width="100">
					<template slot-scope="scope">
						<div @click="toinfo(scope.row)" class="see-button flex-center flex-midden">
							<img src="@/assets/images/see.png"/>
							<div>查看</div>
						</div>
					</template>
				</el-table-column>
			  </el-table>
			  <div class="table-bottom flex-between flex-center">
					  <div class="table-bottom-left flex-start flex-center">
					  </div>
					<div class="table-bottom-right">
						<el-pagination :current-page="page" @size-change="changeSize" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="sizes, prev, pager, next, jumper" :total="total" @current-change="getNextPage">
						</el-pagination>
					</div>
			  </div>
		</div>
		<inputpwd ref="inputpwdRef" @check="checkpwd"></inputpwd>
		<scorenotice ref="scorenoticeRef"></scorenotice>
	</div>
</template>

<script>
	import indexTop from "@/components/index-top.vue"
	import {
		subjectsList,
		examoffices,
		years,
		examseasons,
		paperTypes,
		questionType
	} from "@/api/config"
	import {getListByAnswer} from "@/api/me"
	import inputpwd from "@/components/inputpwd.vue"
	import scorenotice from "@/components/scorenotice.vue"
	export default {
		components:{
			indexTop,
			inputpwd,
			scorenotice
		},
		data(){
			return {
				tableData:[],
				page:1,
				total:0,
				pageSize:10,
				search:{
					name:"",
					answerStatus:"",
					officeId:"",
					subjectId:"",
					paperType:"",
					integral:"",
					questionType:"",
					isPassword:"",
					date:[]
				},
				type:1,
				subject:[],
				examOffice:[],
				paperType:[],
				questionType:[]
			}
		},
		created(){
			this.getConfig();
			this.getList();
		},
		methods:{
			getConfig(){
				subjectsList().then(res => {
					this.subject = res.list;
				})
				examoffices().then(res => {
					this.examOffice = res.list;
				})
				paperTypes().then(res => {
					this.paperType = res.data;
				})
				questionType().then(res => {
					this.questionType = res.data;
				})
			},
			getList(){
				getListByAnswer({
					limit:this.pageSize,
					offset:this.page,
					source:"client",
					...this.search,
					startTime:this.search.date.length == 2?this.search.date[0]:'',
					endTime:this.search.date.length == 2?this.search.date[1]:'',
					answerStatus:this.search.answerStatus===''?-1:this.search.answerStatus,
					isPassword:this.search.isPassword===''?-1:this.search.isPassword,
					integral:this.search.integral===''?'':this.search.integral,
				}).then(res=>{
					if(res.code == 0){
						this.tableData = res.rows;
						this.total = res.total;
					}
				})
			},
			getTopList(){
				this.page = 1;
				this.getList();
			},
			getNextPage(e){
				this.page = e;
				this.getList();
			},
			reset(){
				this.search = {
					name:"",
					answerStatus:"",
					officeId:"",
					subjectId:"",
					paperType:"",
					integral:"",
					questionType:"",
					isPassword:"",
					date:[]
				}
				this.getTopList();
			},
			toinfo(info){
				this.$router.push("/examinfo?id="+info.id+"&key=");
			},
			//改变条数
			changeSize(e){
				this.pageSize = e;
				this.getTopList();
			},
			toexam(){
				this.$router.push("/answer");
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "../assets/table.scss";
</style>