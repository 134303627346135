<template>
	<div class="usercneter">
		<infoTop></infoTop>
		<div class="center-content flex-start">
			<centerLeft :menu="5"></centerLeft>
			<div class="error">
				<div class="error-select flex-start flex-center">
					<div@click="selectSearch('examOffice','')" :class="''==search.examOffice?'active':''">全部</div>
					<div v-for="item in examOffice" @click="selectSearch('examOffice',item.id)" :key="item.id" :class="item.id==search.examOffice?'active':''">{{item.name}}</div>
				</div>
				<div class="error-select-two flex-start flex-center">
					<div@click="selectSearch('subject','')" :class="''==search.subject?'active':''">全部</div>
					<div v-for="item in subject" @click="selectSearch('subject',item.id)" :key="item.id" :class="item.id==search.subject?'active':''">{{item.name}}</div>
				</div>
				<div class="error-list flex-start flex-wrap">
					<div class="error-item" v-for="(item,index) in tableData" :key="index" @click="showInfo(item.questionId)">
						<img :src="item.imageUrl"/>
					</div>
					<div style="text-align: center;width: 100%;" v-if="page == 1 && tableData.length == 0">
						<el-empty description="暂无数据"></el-empty>
					</div>
				</div>
				<div class="table-bottom-right flex-end">
					<el-pagination :current-page="page" :page-size="pageSize" layout="prev, pager, next, jumper" :total="total" @current-change="getNextPage">
					</el-pagination>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	import centerLeft from "@/components/center-left.vue"
	import {searchHistory} from "@/api/index"
	import infoTop from "@/components/info-top.vue"
	import {subjectsList,examoffices} from "@/api/config"
	export default {
		components:{
			centerLeft,
			infoTop
		},
		data() {
			return {
				tableData: [],
				page: 1,
				total: 0,
				pageSize: 6,
				search: {
					examOffice:"",
					subject:"",
					source:'client'
				},
				examOffice: [],
				subject: []
				
			}
		},
		created() {
			this.getConfig();
			this.getList();
		},
		methods: {
			getConfig() {
				subjectsList().then(res => {
					this.subject = res.list;
				})
				examoffices().then(res => {
					this.examOffice = res.list;
				})
			},
			getList() {
				searchHistory({
					limit: this.pageSize,
					offset: this.page,
					...this.search
				}).then(res => {
					if (res.code == 0) {
						this.tableData = res.rows;
						this.total = res.total;
					}
				})
			},
			selectSearch(name,value){
				if(this.search[name] == value){
					this.search[name] = "";
				}else{
					this.search[name] = value;
				}
				this.$forceUpdate();
				this.getTopList();
			},
			getTopList() {
				this.page = 1;
				this.getList();
			},
			getNextPage(e) {
				this.page = e;
				this.getList();
			},
			toinfo(info) {
				window.open(info.url);
			},
			//改变条数
			changeSize(e) {
				this.pageSize = e;
				this.getTopList();
			},
			showInfo(id){
				window.open("/center/hsitoryinfo?id="+id);
			}
			
		}
	}
</script>

<style lang="scss" scoped>
	@import "./index.scss";
	@import "../../assets/table.scss";
	.showinfo{
		background: #FFFFFF;
		width: 600px;
		margin: 0 auto;
		.showinfo-title{
			width: 600px;
			height: auto;
			margin: 0 auto;
			display: block;
		}
		.result-list-item-info{
			margin-top: 27px;
			div{
				font-weight: bold;
				font-size: 14px;
				color: #16191E;
				line-height: 20px;
				text-align: left;
				margin-right: 14px;
				flex: 0 0 60px;
			}
			img{
				width: 300px;
				height: auto;
			}
			video{
				width: 300px;
				height: auto;
			}
			.result-list-item-info-content{
				width: 819px;
				flex: 0 0 819px;
				background: #F6F6F6;
				border-radius: 4px;
				border: 1px solid #F1F1F1;
				padding: 17px;
				box-sizing: border-box;
				font-weight: 400;
				font-size: 14px;
				color: #777C85;
				line-height: 20px;
				text-align: left;
			}
			.result-list-item-info-score{
				height: 20px;
				font-weight: 500;
				font-size: 14px;
				color: #FF5858;
				line-height: 20px;
				text-align: left;
				flex: 0 0 200px;
			}
			.result-list-item-list{
				width: 819px;
				flex: 0 0 819px;
				img{
					width: 92px;
					height: 92px;
					margin-right: 12px;
					margin-bottom: 12px;
					object-fit: contain;
					border-radius: 8px;
					border: 1px solid #E7E7E7;
					box-sizing: border-box;
					cursor: pointer;
				}
			}
		}
	}
	.dialog-footer{
		margin-top: 50px;
	}
	.bottom-one{
		width: 75px;
		height: 34px;
		border-radius: 4px;
		border: 1px solid #FFB80F;
		font-weight: 400;
		font-size: 14px;
		color: #FFB80F;
		line-height: 34px;
		text-align: center;
		margin: 0px 11px;
		cursor: pointer;
	}
	.bottom-two{
		width: 122px;
		height: 34px;
		background: #FFB80F;
		border-radius: 4px;
		font-weight: 400;
		font-size: 14px;
		color: #FFFFFF;
		line-height: 34px;
		text-align: center;
		margin: 0px 11px;
		cursor: pointer;
	}
	.error{
		width: 941px;
		background-color: #fff;
		padding: 50px 30px;
		box-sizing: border-box;
		.error-list{
			height: 570px;
			.error-item{
				margin: 0px 18px 18px 0px;
				img{
					width: 264px;
					height: 264px;
					object-fit: contain;
					cursor: pointer;
				}
			}
		}
	}
	.error-select{
		margin-bottom: 16px;
		overflow-x: auto;
		div{
			height: 34px;
			background: #F8F8F8;
			border-radius: 8px;
			font-weight: 400;
			font-size: 16px;
			color: #7C7C7C;
			line-height: 34px;
			text-align: center;
			margin-right: 20px;
			display: inline-block;
			cursor: pointer;
			padding: 0px 10px;
			white-space: nowrap;
		}
		.active{
			background: #FFB80F;
			color: #fff;
		}
	}
	.error-select-two{
		margin-bottom: 16px;
		overflow-x: auto;
		div{
			height: 34px;
			background: #fff;
			border-radius: 8px;
			font-weight: 400;
			font-size: 16px;
			color: #7C7C7C;
			line-height: 34px;
			text-align: center;
			margin-right: 20px;
			border:1px solid #EEEEEE;
			cursor: pointer;
			display: inline-block;
			cursor: pointer;
			padding: 0px 10px;
			white-space: nowrap;
		}
		.active{
			border:1px solid #FFB80F;
			background: rgba(255, 184, 15, 0.1);
			color: #FFB80F;
		}
	}
	
	::v-deep .addModel{
		border-radius: 10px !important;
	}
	::v-deep .el-dialog__title{
		height: 0px;
	}
	::v-deep .el-dialog--center .el-dialog__body{
		padding: 0px;
	}
</style>