<template>
	<div>
		<div class="info" v-if="step == 1">
			<img class="info-close" @click="tolast" src="@/assets/images/close.png"/>
			<div class="info-title">{{title}}</div>
			<div v-for="(item, index) in list" :key="item.id">
				<div class="info-content">
					<img  :src="item.imageUrl" style="width: 100%;height: auto;"/>
				</div>
				
				<div class="info-score">分值 {{item.score}}分</div>
				
				<div v-if="item.questionType == 1" class="info-answer flex-start">
					<div class="info-answer-title">请选择答案</div>
					<div class="info-answer-select flex-start flex-center">
						<div v-for="value in selectNum" :class="item.userAnswer==unit[value-1]?'active':''" @click.stop="selectAnswer(index,unit[value-1])">{{unit[value-1]}}</div>
					</div>
				</div>
				
				<template v-else>
					<div class="info-answer flex-start">
						<div class="info-answer-title">学生答案</div>
						<textarea placeholder="请输入答案" v-model="item.userAnswer"></textarea>
					</div>
					
					
					<div class="info-answer flex-start">
						<div class="info-answer-title">学生答案</div>
						<div class="info-answer-list flex-start flex-center">
							<div class="info-answer-list-item" v-for="(value, key) in item.fileUrl">
								<img :src="imageurl+value"/>
								<i class="el-icon-error" @click="deleteImage(index,key)"></i>
							</div>
							<el-upload v-if="item.fileUrl.length < 10" :action="upload" name="file" :show-file-list="false" :before-upload="checkImage" :on-success="(info,e)=>{imageSuccess(info,e, index)}">
								<div class="info-answer-list-upload">
									<img src="@/assets/images/search/photo.png"/>
									<div>最多可上传10张</div>
								</div>
							</el-upload>
							
						</div>
					</div>
				</template>
			</div>
			<div style="height: 30px;"></div>
			<div class="info-button flex-round flex-center">
				<el-button @click="saveanswer" :loading="submitStatus">保存并返回</el-button>
				<el-button @click="download" :loading="downStatus">试卷下载</el-button>
				<el-button @click="submitAnswer" :loading="submitStatus">完成考试</el-button>
			</div>
			<notice ref="noticeRef" @ok="submitInfo"></notice>
		</div>
		<div class="result" v-if="step == 2">
			<div class="result-top">
				<img class="result-close" @click="tolast" src="@/assets/images/close.png"/>
				<div class="result-title">{{title}}</div>
				<div class="result-attr flex-start flex-center flex-wrap">
					<div><span>考卷人</span>{{pagerInfo.answerUserName}}</div>
					<div><span>考卷人电话</span>{{pagerInfo.mobile}}</div>
					<div><span>难度</span>{{pagerInfo.difficultyName}}</div>
					<div><span>学生 考卷时间</span>{{pagerInfo.answerTime}}</div>
					<div v-if="pagerInfo.handleAutomatic != 1 && pagerInfo.status < 4 && pagerInfo.notesUserName"><span>判卷老师</span>{{pagerInfo.notesUserName}}</div>
					<div v-if="pagerInfo.handleAutomatic != 1 && pagerInfo.status < 4 && pagerInfo.notes" style="width: 100%;"><span>知识点评语</span>{{pagerInfo.notes}}</div>
				</div>
				<div class="result-score" v-if="pagerInfo.status < 4">试卷报告（{{pagerInfo.percentage}}     {{pagerInfo.answerScore}}分/{{pagerInfo.paperScore}}分）</div>
				<div class="result-num" v-if="pagerInfo.status < 4">
					<span v-for="item in pagerInfo.questions">{{item.name}}（{{item.answerScore}}分/{{item.questionScore}}分）</span>
				</div>
				<div v-if="pagerInfo.status == 4" class="result-button flex-start flex-center">
					<div class="result-button-one" @click="applyPanjuan">申请判卷</div>
					<div v-if="panjuan == false" class="result-button-two" @click="setpanjuan">自我判卷</div>
					<div class="result-button-two" @click="tolast">返回列表</div>
				</div>
				<div v-else-if="pagerInfo.status == 5" class="result-button flex-start flex-center">
					<div class="result-button-two" @click="tolast">返回列表</div>
				</div>
				<template v-else>
					<div v-if="pagerInfo.handleAutomatic == 1" class="result-button flex-start flex-center">
						<div @click="tolast" class="result-button-one">返回列表</div>
						<div class="result-button-two" @click="seeReport" v-if="pagerInfo.reportStatus == 1">查看报告</div>
					</div>
					<div v-else class="result-button flex-start flex-center">
						<div v-if="pagerInfo.status == 1 && pagerInfo.noteFile" @click="seeUrl(pagerInfo.noteFile)" class="result-button-one">批改查阅/下载</div>
						<div class="result-button-two" @click="seeReport" v-if="pagerInfo.reportStatus == 1">查看报告</div>
						<div class="result-button-two" @click="tolast">返回列表</div>
					</div>
				</template>
			</div>
			
			<div class="result-list">
				<div class="result-list-item" v-for="(item, index) in list" :key="item.id">
					<img class="result-list-item-title" :src="item.imageUrl"/>
					<div class="result-list-item-score">分值：{{item.score}}</div>
					
					<!-- 选择题的答案 -->
					<div class="result-list-item-answer flex-start flex-center" v-if="item.questionType == 1">
						<div class="flex-start flex-center">提交答案 <span>{{item.userAnswer}}</span>
							<img v-if="item.userAnswer == item.answer" src="@/assets/images/dui.png"/>
							<img v-else src="@/assets/images/cuo.png"/>
						</div>
						<div>得分 <span>{{item.answerScore}}</span></div>
						<div v-if="item.isViewAnswers == 1">正确答案 <span>{{item.answer}}</span></div>
					</div>
					<!-- 简答题的答案 -->
					<div v-else>
						<div class="result-list-item-info flex-start" v-if="item.userAnswer">
							<div>学生答案</div>
							<div class="result-list-item-info-content">{{item.userAnswer}}</div>
						</div>
						<div class="result-list-item-info flex-start" v-if="item.fileUrl.length > 0">
							<div>学生答案</div>
							<div class="result-list-item-list flex-start flex-center">
								<el-image v-for="(value, key) in item.fileUrl" :src="imageurl+value" fit="contain" :preview-src-list="[imageurl+value]"></el-image>
								<!-- <img v-for="(value, key) in item.fileUrl" :src="imageurl+value"/> -->
							</div>
						</div>
					</div>
					
					
					<div class="result-list-item-button flex-center flex-between" v-if="item.questionType == 1 && item.isViewAnswers == 0">
						<div>查看答案：消耗{{seescore}}积分查看答案</div>
						<span @click="seeAnswer(index)">确定</span>
					</div>
					<div v-if="item.questionType != 1 && item.isViewAnswers == 0" class="flex-between flex-center">
						<div class="panjuan flex-start flex-center" v-if="panjuan == true">
							<div>自我判分</div>
							<el-input class="panjuan-input" type="number" placeholder="请输入分数" v-model="item.answerScore"></el-input>
						</div>
						<div v-else-if="pagerInfo.status == 3" class="result-list-item-info flex-start">
							<div>该题得分</div>
							<div class="result-list-item-info-score">{{item.answerScore}}分</div>
						</div>
						<div v-else></div>
						<div class="result-list-item-button flex-center flex-between">
							<div>查看答案：消耗{{seescore}}积分查看答案</div>
							<span @click="seeAnswer(index)">确定</span>
						</div>
					</div>
					
					<div class="result-list-item-info flex-start" v-if="item.isViewAnswers == 1 && item.questionType != 1">
						<div>题目答案</div>
						<img :src="item.answerUrl"/>
					</div>
					
					
					<div class="result-list-item-info flex-start" v-if="item.isViewAnswers == 1 && item.videoUrl">
						<div>视频讲解</div>
						<video :src="item.videoUrl" controls/>
					</div>
					
					
					<div v-if="item.questionType != 1 && item.isViewAnswers == 1" class="flex-between flex-center">
						<div class="panjuan flex-start flex-center" v-if="panjuan == true">
							<div>自我判分</div>
							<el-input class="panjuan-input" type="number" placeholder="请输入分数" v-model="item.answerScore"></el-input>
						</div>
						<div v-else-if="pagerInfo.status == 3" class="result-list-item-info flex-start">
							<div>该题得分</div>
							<div class="result-list-item-info-score">{{item.answerScore}}分</div>
						</div>
						<div v-else></div>
						<div class="result-list-item-pdf" v-if="item.sourceUrl" @click="topdf(item.sourceUrl)">查看源文档</div>
					</div>
					
					<div class="result-list-item-pdf" v-if="item.questionType == 1 && item.isViewAnswers == 1 && item.sourceUrl" @click="topdf(item.sourceUrl)">查看源文档</div>
				</div>
				
				
			</div>
		</div>
		<score ref="scoreRef" @ok="scoreSubmit"></score>
		<div style="height: 100px;" v-if="panjuan == true"></div>
		<el-button class="button-submit" v-if="panjuan == true"  type="text" @click="submitPanjuan" :loading="submitLoading">完成并提交自我判卷评分</el-button>
	</div>
</template>

<script>
	
	import {getQuestionListByPaper,newPaperAnswerDownload,addPaperAnswers,getNewPaperAnswers,pointsRuleKey,getUserByToken,viewAnswers,submitForReview} from "@/api/index"
	import {uploadUrl,imageurl} from "@/api/config"
	import notice from "@/components/notice.vue"
	import score from "@/components/score.vue"
	export default {
		components:{
			notice,
			score
		},
		data(){
			return {
				id:0,
				key:"",
				list:[],
				title:"",
				selectNum:0,
				unit:["A","B","C","D","E","F","G","H","I","G","K","L","M","N"],
				submitStatus:false,
				downStatus:false,
				upload:"",
				imageurl:"",
				step:1,
				pagerInfo:{},
				seescore:0,//查看答案消耗的积分
				reportscore:0,//查看考卷报告的积分
				panjuscore:0,//申请判卷需要的积分
				panjuan:false,//是否开始判卷
				submitLoading:false
				
			}
		},
		mounted() {
			this.upload = uploadUrl();
			this.imageurl = imageurl();
			this.id = this.$route.query.id || 0;
			this.key = this.$route.query.key || "";
			getNewPaperAnswers({
				paperId:this.id
			}).then(res=>{
				if(res.code == 0){
					if(res.data.status && res.data.status != 0 && res.data.status != 2){
						this.pagerInfo = res.data;
						// handleAutomatic  判卷方式1，自动，2.手动
						this.step = 2;
						this.getScoreNum();
					}else{
						this.step = 1;
					}
					
				}else{
					this.$router.replace("/");
				}
			})
			this.getInfo();
		},
		methods:{
			//查看答案
			seeAnswer(index){
				getUserByToken().then(res=>{
					if(res.code == 0){
						if(res.data.points >= this.seescore || res.data.vipExpireTime > 0){
							if(res.data.vipExpireTime > 0){
								this.scoreSubmit({index:index,type:1});
							}else{
								this.$refs.scoreRef.init(1,"查看答案需要"+this.seescore+"积分，是否查看",{index:index,type:1});
							}
						}else{
							this.$refs.scoreRef.init(2,"当前积分不足请充值或购买会员",{});
						}
					}else{
						this.$message.error(res.msg);
					}
				})
			},
			//积分花费确认
			scoreSubmit(e){
				if(e.type == 1){//确认查看答案
					viewAnswers({
						paperId:this.id,
						questionId:this.list[e.index].questionId,
						ruleKey:"question_answers"
					}).then(res=>{
						if(res.code == 0){
							this.list[e.index].isViewAnswers = 1;
							this.list[e.index].answerUrl = res.data.answerUrl;
							this.list[e.index].sourceUrl = res.data.sourceUrl;
							this.list[e.index].videoUrl = res.data.videoUrl;
						}else{
							this.$message.error(res.msg);
						}
						
					})
				}else if(e.type == 2){//查看报告
					this.$router.push("/report?id="+this.id);
				}else if(e.type == 3){//申请判卷
					submitForReview({
						newPaperId:this.id,
						status:5,
						questionJson:[]
					}).then(res=>{
						if(res.code == 0){
							this.pagerInfo.status = 5;
							this.$message.success("申请成功");
						}else{
							this.$message.error(res.msg);
						}
					})
				}
			},
			//获取各种积分消耗数量
			getScoreNum(){
				pointsRuleKey("question_answers").then(res=>{
					this.seescore = res.data.points < 0 ?res.data.points*-1:res.data.points;
				})
				pointsRuleKey("view_report").then(res=>{
					this.reportscore = res.data.points < 0 ?res.data.points*-1:res.data.points;
				})
				pointsRuleKey("application_judgment").then(res=>{
					this.panjuscore = res.data.points < 0 ?res.data.points*-1:res.data.points;
				})
				
			},
			getInfo(){
				getQuestionListByPaper({
					paperId:this.id,
					password:this.key
				}).then(res=>{
					if(res.code == 0){
						res.data.map(item=>{
							item.fileUrl = item.fileUrl?item.fileUrl:[];
						})
						this.list = res.data;
						this.title = res.name;
						this.selectNum = res.singleAnswerNum;
					}else{
						this.$router.replace("/");
					}
				})
			},
			selectAnswer(index,value){
				this.list[index].userAnswer = value;
				this.$forceUpdate();
			},
			download(){
				this.downStatus = true;
				newPaperAnswerDownload({
					id:this.id,
					type:2
				}).then(res=>{
					const url = window.URL.createObjectURL(new Blob([res]))
					const link = document.createElement("a")
					link.href = url
					link.setAttribute("download",this.title+'.pdf')//导出的格式一定要和接口返回的文件一致
					document.body.appendChild(link)
					link.click()
					window.URL.revokeObjectURL(url)
					document.body.removeChild(link)
					this.downStatus = false;
				})
			},
			saveanswer(){
				this.submitStatus = true;
				let data = [];
				this.list.map(item=>{
					data.push({
						newPaperId:item.newPaperId,
						answerId:this.$cookies.get("userId"),
						questionId:item.questionId,
						answer:item.userAnswer,
						fileUrls:item.fileUrl.join(",")
					});
				})
				addPaperAnswers({
					newPaperId:this.id,
					status:2,
					answerId:this.$cookies.get("userId"),
					questionJson:data
				}).then(res=>{
					if(res.code == 0){
						this.$message.success("保存成功");
						this.$router.go(-1); 
					}else{
						this.submitStatus = false;
						return this.$message.error(res.msg);
					}
				})
			},
			submitAnswer(){
				this.$refs.noticeRef.init("温馨提示","完成答卷后将无法修改，请核验无误","确认", {});
				
			},
			submitInfo(){
				let data = [];
				let submit = true;
				this.list.map(item=>{
					if(item.fileUrl.length == 0 && !item.userAnswer){
						submit  = false;
					}
					data.push({
						newPaperId:item.newPaperId,
						answerId:this.$cookies.get("userId"),
						questionId:item.questionId,
						answer:item.userAnswer,
						fileUrls:item.fileUrl.join(",")
					});
				})
				if(!submit){
					return this.$message.error("当前题目未答完");
				}
				this.submitStatus = true;
				addPaperAnswers({
					newPaperId:this.id,
					status:4,
					answerId:this.$cookies.get("userId"),
					questionJson:data
				}).then(res=>{
					if(res.code == 0){
						this.$message.success("提交成功");
						this.$router.go(-1); 
					}else{
						this.submitStatus = false;
						return this.$message.error(res.msg);
					}
				})
			},
			//限制图片的相关格式和大小
			checkImage(file){
				const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg');
				const isLt2M = file.size / 1024 / 1024 < 6;
				if (!isJPG) {
				  this.$message.error('上传图片只能是JPG,JPEG,PNG格式!');
				}
				return isJPG && isLt2M;
			},
			//上传观测点的图片成功
			imageSuccess(info,e, index){
				if(e.response.code == 0){
					this.list[index].fileUrl.push(e.response.fileName);
				}else{
					this.$message.error("图片上传失败");
				}
			},
			deleteImage(index,key){
				this.list[index].fileUrl.splice(key, 1);
			},
			tolast(){
				this.$router.go(-1); 
			},
			topdf(url){
				window.open(url);
			},
			seeReport(){
				if(this.pagerInfo.isReport == 1){
					this.$router.push("/report?id="+this.id);
				}else{
					getUserByToken().then(res=>{
						if(res.code == 0){
							if(res.data.points >= this.reportscore || res.data.vipExpireTime > 0){
								if(res.data.vipExpireTime > 0){
									this.scoreSubmit({type:2});
								}else{
									this.$refs.scoreRef.init(1,"查看报告需要"+this.reportscore+"积分，是否查看",{type:2});
								}
							}else{
								this.$refs.scoreRef.init(2,"当前积分不足请充值或购买会员",{});
							}
						}else{
							this.$message.error(res.msg);
						}
					})
				}
				
			},
			//申请判卷
			applyPanjuan(){
				getUserByToken().then(res=>{
					if(res.code == 0){
						if(res.data.points >= this.panjuscore || res.data.vipExpireTime > 0){
							if(res.data.vipExpireTime > 0){
								this.scoreSubmit({type:3});
							}else{
								this.$refs.scoreRef.init(1,"申请判卷需要"+this.panjuscore+"积分，是否申请",{type:3});
							}
						}else{
							this.$refs.scoreRef.init(2,"当前积分不足请充值或购买会员",{});
						}
					}else{
						this.$message.error(res.msg);
					}
				})
			},
			//开始自我判断
			setpanjuan(){
				this.panjuan = true;
			},
			seeUrl(url){
				window.open(this.imageurl+url);
			},
			//提交自动判卷
			submitPanjuan(){
				let data = [];
				let submit = true;
				this.list.map(item=>{
					if(item.questionType != 1){
						if(item.answerScore === null || item.score < item.answerScore){
							submit  = false;
						}
						
						data.push({
							newPaperId:item.newPaperId,
							answerId:this.$cookies.get("userId"),
							questionId:item.questionId,
							score:item.answerScore
						});
					}else{
						data.push({
							newPaperId:item.newPaperId,
							answerId:this.$cookies.get("userId"),
							questionId:item.questionId,
							score:0
						});
					}
					
				})
				if(!submit){
					return this.$message.error("有题目未评分或超出分值");
				}
				this.submitLoading = true;
				submitForReview({
					newPaperId:this.id,
					status:3,
					questionJson:data
				}).then(res=>{
					if(res.code == 0){
						this.submitLoading = false;
						this.pagerInfo.status = 3;
						this.panjuan = false;
						this.$forceUpdate();
						this.$message.success("提交成功");
					}else{
						this.submitLoading = false;
						this.$message.error(res.msg);
					}
				})
			}
		}
	}
</script>


<style lang="scss" scoped>
	@import "../assets/info.scss";
</style>