<template>
	<div class="center-left">
		<!-- <img src="@/assets/images/setting.png" class="center-left-setting"/> -->
		<div class="center-left-user">
			<img v-if="info.avatar" :src="info.avatar"/>
			<img v-else src="@/assets/images/login/avatar.png"/>
			<div>{{info.nickname}}</div>
			<div>{{info.mobile}}</div>
		</div>
		<div class="center-left-menu">
			<router-link to="/center" class="center-left-menu-item flex-start flex-center" :class="menu==1?'active':''">
				<span class="active-text"></span>
				<img v-if="menu == 1" src="@/assets/images/me/11.png"/>
				<img v-else src="@/assets/images/me/1.png"/>
				<div>个人资料</div>
			</router-link>
			<router-link to="/center/report" class="center-left-menu-item flex-start flex-center" :class="menu==2?'active':''">
				<span class="active-text"></span>
				<img v-if="menu == 2" src="@/assets/images/me/22.png"/>
				<img v-else src="@/assets/images/me/2.png"/>
				<div>我的报告</div>
			</router-link>
			<router-link to="/center/score" class="center-left-menu-item flex-start flex-center" :class="menu==3?'active':''">
				<span class="active-text"></span>
				<img v-if="menu == 3" src="@/assets/images/me/33.png"/>
				<img v-else src="@/assets/images/me/3.png"/>
				<div>我的积分<span>（{{info.points}}积分）</span></div>
			</router-link>
			<router-link to="/center/member" class="center-left-menu-item flex-start flex-center" :class="menu==4?'active':''">
				<span class="active-text"></span>
				<img v-if="menu == 4" src="@/assets/images/me/44.png"/>
				<img v-else src="@/assets/images/me/4.png"/>
				<div>我的会员</div>
			</router-link>
			<router-link to="/center/hsitory" class="center-left-menu-item flex-start flex-center" :class="menu==5?'active':''">
				<span class="active-text"></span>
				<img v-if="menu == 5" src="@/assets/images/me/55.png"/>
				<img v-else src="@/assets/images/me/5.png"/>
				<div>我的足迹</div>
			</router-link>
			<router-link to="/center/errorquestion" class="center-left-menu-item flex-start flex-center" :class="menu==6?'active':''">
				<span class="active-text"></span>
				<img v-if="menu == 6" src="@/assets/images/me/66.png"/>
				<img v-else src="@/assets/images/me/6.png"/>
				<div>错题集</div>
			</router-link>
			<router-link to="/center/agree" class="center-left-menu-item flex-start flex-center" :class="menu==7?'active':''">
				<span class="active-text"></span>
				<img v-if="menu == 7" src="@/assets/images/me/77.png"/>
				<img v-else src="@/assets/images/me/7.png"/>
				<div>用户协议</div>
			</router-link>
			<router-link to="/center/privacy" class="center-left-menu-item flex-start flex-center" :class="menu==8?'active':''">
				<span class="active-text"></span>
				<img v-if="menu == 8" src="@/assets/images/me/88.png"/>
				<img v-else src="@/assets/images/me/8.png"/>
				<div>隐私协议</div>
			</router-link>
			
		</div>
		<div class="user-left-quit" @click="submitexit">退出登录</div>
	</div>
</template>

<script>
	import {getUserByToken,outLogin} from "@/api/index"
	export default {
		props: {
			menu: {
				type: Number,
				value: 1
			}
		},
		data(){
			return {
				dialogVisible:false,
				name:"",
				info:{}
			}
		},
		mounted() {
			getUserByToken().then(res=>{
				if(res.code == 0){
					this.info = res.data;
				}
			})
		},
		methods:{
			submitexit(){
				outLogin().then(res=>{
					if(res.code == 0){
						this.$cookies.set('userToken', "");
						this.$cookies.set('userId', "");
						this.$router.replace({name:"login"});
					}else{
						this.$message.error(res.msg);
					}
				})
				
			}
		}
	}
</script>

<style scoped lang="scss">
	.center-left{
		width: 259px;
		height: 830px;
		flex:0 0 259px;
		background: #FFFAF5;
		border-radius: 8px 0px 0px 8px;
		position: relative;
		padding: 35px 0px 0px;
		box-sizing: border-box;
		.center-left-setting{
			width: 23px;
			height: 23px;
			position: absolute;
			left: 16px;
			top: 16px;
		}
		.center-left-user{
			text-align: center;
			img{
				width: 78px;
				height: 78px;
				background: #E1D0C6;
				border-radius: 100%;
				object-fit: cover;
			}
			div{
				&:first-of-type{
					font-weight: 600;
					font-size: 16px;
					color: #16191E;
					line-height: 22px;
					margin-top: 12px;
				}
				&:last-of-type{
					font-weight: 400;
					font-size: 12px;
					color: #777C85;
					line-height: 17px;
				}
			}
		}
		.center-left-menu{
			margin-top: 23px;
			.center-left-menu-item{
				height: 49px;
				line-height: 49px;
				padding-left: 31px;
				box-sizing: border-box;
				cursor: pointer;
				text-decoration: none;
				img{
					width: 18px;
					height: 18px;
					margin-right: 10px;
				}
				div{
					font-weight: 400;
					font-size: 16px;
					color: #16191E;
					span{
						color: #FFB80F;
					}
				}
			}
			.active{
				background-color: #fff;
				position: relative;
				.active-text{
					width: 4px;
					height: 24px;
					background: #FFB80F;
					position: absolute;
					left: 0px;
					top: 13px;
				}
				div{
					font-weight: 400;
					font-size: 16px;
					color: #FFB80F;
					span{
						color: #FFB80F;
					}
				}
			}
		}
		
		.user-left-quit{
			width: 119px;
			height: 41px;
			border-radius: 8px;
			border: 1px solid #DCDCDC;
			font-weight: 400;
			font-size: 14px;
			color: #777C85;
			line-height: 41px;
			text-align: center;
			margin: 160px auto 0px;
			cursor: pointer;
		}
	}
</style>