<template>
	<div class="usercneter">
		<infoTop></infoTop>
		<div class="center-content flex-start">
			<centerLeft :menu="1"></centerLeft>
			<div class="user-info">
				<div class="user-info-item flex-start flex-center">
					<div class="user-info-item-title">头像</div>
					<el-upload :action="upload" name="file" :limit="1" :show-file-list="false" :before-upload="checkImage" :on-success="imageSuccess">
						<div class="user-info-item-avatar">
							<img v-if="avatar" :src="avatar"/>
							<img v-else src="@/assets/images/login/avatar.png"/>
							<div>点击可更换头像</div>
						</div>
					</el-upload>
				</div>
				
				<div class="user-info-item flex-start flex-center">
					<div class="user-info-item-title">昵称</div>
					<div class="user-info-item-input">
						<el-input v-model="form.nickname" placeholder="请输入昵称"></el-input>
					</div>
				</div>
				
				<div class="user-info-item flex-start flex-center">
					<div class="user-info-item-title">所在地区</div>
					<div class="user-info-item-input">
						<el-select v-model="form.region" style="width: 100%;" placeholder="请选择所在地区">
							<el-option v-for="item in area" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
				</div>
				<div class="user-info-item flex-start flex-center">
					<div class="user-info-item-title">学校</div>
					<div class="user-info-item-input">
						<el-input v-model="form.school" placeholder="请输入学校"></el-input>
					</div>
				</div>
				<div class="user-info-item flex-start flex-center">
					<div class="user-info-item-title">年级</div>
					<div class="user-info-item-input">
						<el-select v-model="form.grade" style="width: 100%;" placeholder="请选择年级">
							<el-option v-for="item in grade" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
				</div>
				<div class="user-info-item flex-start flex-center">
					<div class="user-info-item-title">课程体系</div>
					<div class="user-info-item-input">
						<el-select v-model="form.courseSystem" style="width: 100%;" placeholder="请选择课程体系">
							<el-option v-for="item in examofficesList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
				</div>
				<div class="user-info-item flex-start flex-center">
					<div class="user-info-item-title">角色</div>
					<div class="user-info-item-input">
						<el-select v-model="form.role" style="width: 100%;" placeholder="请选择角色">
							<el-option label="学生" value="1"></el-option>
							<el-option label="老师" value="2"></el-option>
						</el-select>
					</div>
				</div>
				
				<div class="user-info-button" @click="submit">保存</div>
				<!-- <div class="user-info-text">完善信息，可获得100积分</div> -->
			</div>
		</div>
	</div>
</template>

<script>
	import infoTop from "@/components/info-top.vue"
	import centerLeft from "@/components/center-left.vue"
	import {getUserByToken,dataFilling} from "@/api/index"
	import {uploadUrl,areaList,examoffices,gradeList} from "@/api/config"
	export default {
		components:{
			infoTop,
			centerLeft
		},
		data(){
			return {
				rules:{
					nickname: [
						{ required: true, message: '请输入昵称', trigger: 'blur' },
					],
					region: [
						{ required: true, message: '请选择地区', trigger: 'blur' }
					],
					school: [
						{ required: true, message: '请输入学校', trigger: 'blur' }
					],
					grade: [
						{ required: true, message: '请选择年级', trigger: 'blur' }
					],
					courseSystem: [
						{ required: true, message: '请选择课程体系', trigger: 'blur' }
					]
				},
				form:{
					nickname:"",
					region:"",
					school:"",
					courseSystem:"",
					avatarString:"",
					grade:"",
					role:"1"
				},
				upload:"",
				avatar:"",
				area:[],
				examofficesList:[],
				grade:[]
			}
		},
		mounted() {
			this.upload = uploadUrl();
			areaList().then(res=>{
				this.area = res.data;
			})
			gradeList().then(res=>{
				this.grade = res.list;
			})
			examoffices().then(res=>{
				this.examofficesList = res.list;
			})
			getUserByToken().then(res=>{
				this.form.nickname = res.data.nickname;
				this.form.region = Number(res.data.region);
				this.form.school = res.data.school;
				this.form.courseSystem = Number(res.data.courseSystem);
				this.form.grade = Number(res.data.grade);
				this.form.role = String(res.data.role);
				this.form.avatarString = "";
				this.avatar = res.data.avatar;
			})
		},
		methods:{
			//限制图片的相关格式和大小
			checkImage(file){
				const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg');
				const isLt2M = file.size / 1024 / 1024 < 6;
				if (!isJPG) {
				  this.$message.error('上传图片只能是JPG,JPEG,PNG格式!');
				}
				return isJPG && isLt2M;
			},
			//上传观测点的图片成功
			imageSuccess(info,e){
				if(e.response.code == 0){
					this.avatar = e.response.filePath;
					this.form.avatarString = e.response.fileName;
				}else{
					this.$message.error("图片上传失败");
				}
			},
			submit(){
				if(!this.form.avatarString && !this.avatar){
					return this.$message.error("请上传头像");
				}
				if(!this.form.nickname){
					return this.$message.error("请输入昵称");
				}
				if(!this.form.region){
					return this.$message.error("请选择地区");
				}
				if(!this.form.grade){
					return this.$message.error("请选择年级");
				}
				if(!this.form.school){
					return this.$message.error("请输入学校");
				}
				if(!this.form.courseSystem){
					return this.$message.error("请选择课程体系");
				}
				dataFilling({...this.form,id:this.$cookies.get('userId')}).then(res=>{
					if(res.code == 0){
						location.reload();
						this.$message.success("保存成功");
					}else{
						return this.$message.error(res.msg);
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "./index.scss";
</style>