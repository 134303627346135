import request from '@/utils/request'
import VueCookies from 'vue-cookies'


// 登录接口
export function login(data) {
  return request({
    url: 'paper/user/pcLogin',
    method: 'post',
	params:data
  })
}


// 微信扫码登录
export function wechatLogin(data) {
  return request({
    url: 'paper/wechat/generateQrCodeUrl',
    method: 'get',
	params:data
  })
}


// 微信信息验证
export function wechatCodeLogin(data) {
  return request({
    url: 'paper/wechat/login',
    method: 'post',
	params:data
  })
}

// 获取协议的相关接口
export function protocol(data) {
  return request({
    url: 'sys/agreement/type',
    method: 'get',
	params:data
  })
}

// 忘记密码
export function forgotPassword(data) {
  return request({
    url: 'paper/user/forgotPassword',
    method: 'post',
	params:data
  })
}



// 发送短信验证码
export function sendSms(data) {
  return request({
    url: 'common/verify/sendSms',
    method: 'post',
	params:data
  })
}





// 发送忘记的短信验证码
export function sendForgotSms(data) {
  return request({
    url: 'common/verify/sendForgotSms',
    method: 'post',
	params:data
  })
}



// 发送邮箱验证码
export function sendEmail(data) {
  return request({
    url: 'common/verify/sendEmail',
    method: 'post',
	data:data,
    headers: {
	  'Content-Type': 'multipart/form-data'
	}
  })
}



// 账号注册
export function pcRegister(data) {
  return request({
    url: 'paper/user/pcRegister',
    method: 'post',
	params:data
  })
}

//完善用户信息
export function dataFilling(data) {
  return request({
    url: 'paper/user/dataFilling',
    method: 'post',
	params:data
  })
}
//获取用户的基础信息
export function getUserByToken() {
  return request({
    url: 'paper/user/getUserByToken',
    method: 'post'
  })
}


//获取考卷列表
export function newPaperList(data) {
	data.answerId = VueCookies.get('userId') || 0;
  return request({
    url: '/paper/newPaper/list',
    method: 'get',
	params:data
  })
}



//获取二维码
export function getKeyCode() {
  return request({
    url: 'paper/help/getKey?key=association_qrcode',
    method: 'get'
  })
}


//验证阅卷的密码是否准群
export function lookPaperPassword(data) {
  return request({
    url: 'paper/newPaper/lookPaperPassword',
    method: 'post',
	params:data
  })
}


//获取个人考卷的情况
export function getNewPaperAnswers(data) {
	
	data.answersId = VueCookies.get('userId') || 0;
	return request({
		url: 'paper/answers/getNewPaperAnswers',
		method: 'get',
		params:data
	})
}

//获取试卷详情
export function getQuestionListByPaper(data) {
	
	data.answersId = VueCookies.get('userId') || 0;
	return request({
		url: 'paper/newPaper/getQuestionListByPaper',
		method: 'get',
		params:data
	})
}


//试卷下载
export function newPaperAnswerDownload(data) {
	
	data.answersId = VueCookies.get('userId') || 0;
	return request({
		url: 'backend/teacher/newPaperAnswerDownload',
		method: 'post',
		data:data
	})
}


//保存试卷提交答案
export function addPaperAnswers(data) {
	
	data.answerId = VueCookies.get('userId') || 0;
	return request({
		url: 'paper/answers/addPaperAnswers',
		method: 'post',
		data:data
	})
}

//获取试题列表
export function studyFileList(data) {
  return request({
    url: 'paper/studyFile/list',
    method: 'get',
	params:data
  })
}

//获取内容播报
export function broadcast() {
  return request({
    url: 'paper/broadcast/list2',
    method: 'get'
  })
}

//批量下载试卷
export function batchDownload(data) {
  return request({
    url: 'paper/studyFile/batchDownload',
    method: 'post',
	data:data,
    headers: {
	  'Content-Type': 'multipart/form-data'
	},
	responseType: 'blob'
  })
}

//完善注册用户信息用户信息
export function wechatDataFilling(data) {
  return request({
    url: 'paper/user/dataFilling',
    method: 'post',
	params:data,
    headers: {
	  'Authorization': 'Bearer '+VueCookies.get('wechatUserToken')
	},
  })
}
//获取各种积分比例
export function pointsRuleKey(ruleKey) {
  return request({
    url: 'paper/pointsRule/getOneByKey?key='+ruleKey,
    method: 'get'
  })
}


//查看答案
export function viewAnswers(data) {
	data.answersId = VueCookies.get('userId') || 0;
  return request({
    url: 'paper/answers/viewAnswers',
    method: 'post',
	params:data
  })
}



//查看考卷报告
export function getPaperReport(data) {
	data.answerId = VueCookies.get('userId') || 0;
  return request({
    url: 'paper/answers/getPaperReport',
    method: 'get',
	params:data
  })
}


//判卷申请
export function submitForReview(data) {
	data.answerId = VueCookies.get('userId') || 0;
  return request({
    url: 'paper/answers/submitForReview',
    method: 'post',
	data:data
  })
}




//文字搜索接口
export function picsearchText(data) {
  return request({
    url: 'paper/picsearch/text',
    method: 'post',
	data:data,
    headers: {
	  'Content-Type': 'multipart/form-data'
	}
  })
}

//图片搜索
export function picsearchImage(data) {
  return request({
    url: 'paper/picsearch/upload',
    method: 'post',
	data:data,
    headers: {
	  'Content-Type': 'multipart/form-data'
	}
  })
}



//获取搜题详情
export function questionInfo(data) {
  return request({
    url: 'paper/question/get',
    method: 'post',
	params:data
  })
}

//获取搜题详情
export function questionErrorsTypes() {
  return request({
    url: 'system/questionErrorsTypes/list2',
    method: 'get'
  })
}
//提交错题类型
export function submitQuestionError(data) {
	data.commitUserId = VueCookies.get('userId') || 0;
  return request({
    url: 'paper/questionErrors/save',
    method: 'post',
	params:data
  })
}





//获取绘图报告
export function downloadReport(data) {
	data.answerId = VueCookies.get('userId') || 0;
  return request({
    url: 'paper/newPaper/exportPaperReport',
    method: 'post',
	params:data,
    headers: {
	  'Content-Type': 'multipart/form-data'
	},
	responseType: 'blob'
  })
}





//获取相关知识点
export function getKnowledgeList(data) {
  return request({
    url: 'backend/teacher/getKnowledgeList',
    method: 'get',
	params:data
  })
}


//获取我的报告
export function getReportList(data) {
	data.answerId = VueCookies.get('userId') || 0;
  return request({
    url: 'paper/answers/getReport',
    method: 'get',
	params:data
  })
}

//我的足迹

export function searchHistory(data) {
  return request({
    url: 'paper/searchHistory/list2',
    method: 'post',
	data:data
  })
}

//退出登录

export function outLogin() {
  return request({
    url: 'paper/user/outLogin',
    method: 'post'
  })
}
//查看题目详情
export function wrongQuestionDetails(id) {
  return request({
    url: 'paper/answers/wrongQuestionDetails/'+id,
    method: 'get'
  })
}


//查看题目详情
export function payList() {
  return request({
    url: 'paper/product/list2',
    method: 'post'
  })
}


//支付
export function pay(pid, params) {
  return request({
    url: 'paper/pay/pcRecharge/'+pid,
    method: 'post',
	params:params
  })
}

//微信支付检测是否支付成
export function pcNotify( params) {
  return request({
    url: '/paper/pay/pcNotify',
    method: 'post',
	params:params
  })
}


//支付宝支付接口
export function zfbpay(pid, params) {
  return request({
    url: 'paper/pay/aliRecharge/'+pid,
    method: 'post',
	params:params
  })
}
