<template>
	<div class="usercneter">
		<infoTop></infoTop>
		<div class="center-content flex-start">
			<centerLeft :menu="5"></centerLeft>
			<div class="error">
				<div class="search-info">
					<div class="search-info-desc">
						<div class="flex-start flex-center">
							<div><span>考试局</span>{{info.examOfficeName}}</div>
							<div><span>科目</span>{{info.subjectName}}</div>
							<div><span>年份</span>{{info.yearName}}</div>
							<div><span>考试季</span>{{info.examSeasonName}}</div>
							<div><span>难度</span>{{info.difficultyName}}</div>
						</div>
						<div class="flex-start flex-center">
							<div><span>知识点一</span>{{info.knowledgeName1}}</div>
							<div><span>知识点二</span>{{info.knowledgeName2}}</div>
							<div><span>知识点三</span>{{info.knowledgeName3}}</div>
						</div>
					</div>
					
					<div class="search-info-title">题目</div>
					
					<div class="search-info-content">
						<img :src="info.imageUrl" style="width: 100%;height: auto;"/>
					</div>
					<div class="flex-start">
						<div class="search-info-title">题目答案</div>
						<img :src="info.answerUrl" class="search-info-img"/>
					</div>
					
					<div class="flex-start" v-if="info.videoUrl">
						<div class="search-info-title">视频讲解</div>
						<video :src="info.videoUrl" class="search-info-img" controls/>
						<!-- <img :src="info.videoUrlCover" class="search-info-img"/> -->
					</div>
				</div>
				
				<div class="search-info-button flex-round flex-center">
					<div @click="showpdf('questionUrl')">查看原题文档</div>
					<div @click="showpdf('answerUrl')">查看原题答案</div>
					<div @click="showError">纠错</div>
				</div>
			</div>
		</div>
		
		<error ref="errorRef"></error>
	</div>
</template>

<script>
	import error from "@/components/error.vue"
	import centerLeft from "@/components/center-left.vue"
	import {questionInfo} from "@/api/index"
	import infoTop from "@/components/info-top.vue"
	export default {
		components:{
			centerLeft,
			infoTop,
			error
		},
		data() {
			return {
				info:{}
			}
		},
		created() {
			questionInfo({
				questionId:this.$route.query.id || 0,
				searchId:0,
				clickCount:0
			}).then(res=>{
				if(res.code == 0){
					this.info = res.question;
					this.pager = res.paper;
					this.type = 4;
				}else{
					this.$message.error(res.msg);
				}
			})
		},
		methods: {
			
			showError(){
				this.$refs.errorRef.init(this.info.id);
			},
			showpdf(field){
				window.open(this.pager[field]);
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "./index.scss";
	.error{
		width: 941px;
		background-color: #fff;
		height: 830px;
		position: relative;
	}
	.search-info{
		width: 100%;
		background: #FFFFFF;
		border-radius: 8px;
		padding: 20px 40px 42px;
		box-sizing: border-box;
		margin: 0 auto;
		overflow: auto;
		height: 830px;
		
		.search-info-desc{
			width: 100%;
			background: #FFF9EF;
			border-radius: 8px;
			padding: 7px 21px;
			box-sizing: border-box;
			
			.flex-start{
				div{
					font-weight: 400;
					font-size: 14px;
					color: #777C85;
					line-height: 20px;
					margin-right: 50px;
					padding: 5px 0px;
					span{
						color: #16191E;
						margin-right: 10px;
					}
				}
			}
		}
		
		.search-info-title{
			font-weight: bold;
			font-size: 14px;
			color: #16191E;
			line-height: 20px;
			text-align: left;
			margin-top: 30px;
		}
		
		.search-info-content{
			font-weight: 400;
			font-size: 14px;
			color: #16191E;
			line-height: 20px;
			margin-top: 9px;
		}
		.search-info-img{
			width: 266px;
			height: 190px;
			margin-left: 14px;
			margin-top: 30px;
			object-fit: cover;
		}
	}
	
	.search-info-button{
		width: 459px;
		height: 60px;
		background: #FFFFFF;
		box-shadow: 0px 0px 10px 0px rgba(175,175,175,0.22);
		border-radius: 8px;
		position: absolute;
		right: 16px;
		bottom: 8px;
		div{
			width: 122px;
			height: 34px;
			background: #FFB80F;
			border-radius: 4px;
			font-weight: 400;
			font-size: 14px;
			color: #FFFFFF;
			line-height: 34px;
			text-align: center;
			cursor: pointer;
		}
	}
	
</style>