<template>
	<div class="login">
		<div class="login-login">
			<div class="login-login-bg">
				<img src="@/assets/images/index/bg-icon.png"/>
			</div>
			<div class="login-model">
				<div class="login-model-text">设置信息</div>
				<div class="user-info">
					<div class="user-info-item flex-start flex-center">
						<div class="user-info-item-title">头像</div>
						<el-upload :action="upload" name="file" :limit="1" :show-file-list="false" :before-upload="checkImage" :on-success="imageSuccess">
							<div class="user-info-item-avatar">
								<img v-if="avatar" :src="avatar"/>
								<img v-else src="@/assets/images/login/avatar.png"/>
								<div>点击可更换头像</div>
							</div>
						</el-upload>
					</div>
					
					
					<div class="user-info-item flex-start flex-center">
						<div class="user-info-item-title">手机号码</div>
						<div class="user-info-item-input">
							<el-input v-model="form.mobile" placeholder="请输入您的手机号码"></el-input>
						</div>
					</div>
					<div class="user-info-item flex-start flex-center">
						<div class="user-info-item-title">验证码</div>
						<div class="user-info-item-input">
							<el-input v-model="form.verify" placeholder="请输入验证码">
								<div slot="suffix" class="send-code" v-if="send">{{time}}S</div>
								<div slot="suffix" class="send-code" v-else @click="sendCode">获取验证码</div>
							</el-input>
						</div>
					</div>
					<div class="user-info-item flex-start flex-center">
						<div class="user-info-item-title">昵称</div>
						<div class="user-info-item-input">
							<el-input v-model="form.nickname" placeholder="请输入昵称"></el-input>
						</div>
					</div>
					
					<div class="user-info-item flex-start flex-center">
						<div class="user-info-item-title">所在地区</div>
						<div class="user-info-item-input">
							<el-select v-model="form.region" style="width: 100%;" placeholder="请选择所在地区">
								<el-option v-for="item in area" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</div>
					</div>
					<div class="user-info-item flex-start flex-center">
						<div class="user-info-item-title">学校</div>
						<div class="user-info-item-input">
							<el-input v-model="form.school" placeholder="请输入学校"></el-input>
						</div>
					</div>
					<div class="user-info-item flex-start flex-center">
						<div class="user-info-item-title">年级</div>
						<div class="user-info-item-input">
							<el-select v-model="form.grade" style="width: 100%;" placeholder="请选择年级">
								<el-option v-for="item in grade" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</div>
					</div>
					<div class="user-info-item flex-start flex-center">
						<div class="user-info-item-title">课程体系</div>
						<div class="user-info-item-input">
							<el-select v-model="form.courseSystem" style="width: 100%;" placeholder="请选择课程体系">
								<el-option v-for="item in examofficesList" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</div>
					</div>
					<div class="user-info-item flex-start flex-center">
						<div class="user-info-item-title">角色</div>
						<div class="user-info-item-input">
							<el-select v-model="form.role" style="width: 100%;" placeholder="请选择角色">
								<el-option label="学生" value="1"></el-option>
								<el-option label="老师" value="2"></el-option>
							</el-select>
						</div>
					</div>
					
						<div class="form-button">
							<div @click="tolast">取消</div>
							<div @click="submitForm()">提交</div>
						</div>
					<!-- <div class="user-info-text">完善信息，可获得100积分</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {getUserByToken,wechatDataFilling,sendSms} from "@/api/index"
	import {uploadUrl,areaList,examoffices,gradeList} from "@/api/config"
	export default {
		data(){
			return {
				isselect:false,
				showpwd:false,//是否展示密码
				send:false,
				time:0,
				timeFun:null,
				form:{
					mobile:"",
					verify:"",
					nickname:"",
					region:"",
					school:"",
					courseSystem:"",
					avatarString:"",
					grade:"",
					role:"1"
				},
				upload:"",
				avatar:"",
				area:[],
				examofficesList:[],
				grade:[]
			}
		},
		
		mounted() {
			this.upload = uploadUrl();
			areaList().then(res=>{
				this.area = res.data;
			})
			gradeList().then(res=>{
				this.grade = res.list;
			})
			examoffices().then(res=>{
				this.examofficesList = res.list;
			})
		},
		methods:{
			sendCode(){
				if(this.send){return false;}
				if(this.form.mobile == ""){
					return this.$message.error("请输入手机号");
				}
				let reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
				if(!reg.test(this.form.mobile)){
					return this.$message.error("请输入正确的手机号");
				}			
				sendSms({phone:this.form.mobile}).then(res=>{
					if(res.code == 0){
						this.send = true;
						this.time = 60;
						this.changeTime();
					}else{
						return this.$message.error(res.msg);
					}
				})
				
			},
			changeTime(){
				if(this.timeFun != null){
					clearTimeout(this.timeFun);
				}
				this.timeFun = setTimeout(()=>{
					this.time--;
					if(this.time > 0){
						this.changeTime();
					}else{
						this.send = false;
					}
				},1000);
			},
			//限制图片的相关格式和大小
			checkImage(file){
				const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg');
				const isLt2M = file.size / 1024 / 1024 < 6;
				if (!isJPG) {
				  this.$message.error('上传图片只能是JPG,JPEG,PNG格式!');
				}
				return isJPG && isLt2M;
			},
			//上传观测点的图片成功
			imageSuccess(info,e){
				if(e.response.code == 0){
					this.avatar = e.response.filePath;
					this.form.avatarString = e.response.fileName;
				}else{
					this.$message.error("图片上传失败");
				}
			},
			submitForm(){
				if(!this.form.avatarString && !this.avatar){
					return this.$message.error("请上传头像");
				}
				if(!this.form.nickname){
					return this.$message.error("请输入昵称");
				}
				if(!this.form.mobile){
					return this.$message.error("请输入手机号");
				}
				if(!this.form.verify){
					return this.$message.error("请输入验证码");
				}
				if(!this.form.nickname){
					return this.$message.error("请输入昵称");
				}
				if(!this.form.region){
					return this.$message.error("请选择地区");
				}
				if(!this.form.grade){
					return this.$message.error("请选择年级");
				}
				if(!this.form.school){
					return this.$message.error("请输入学校");
				}
				if(!this.form.courseSystem){
					return this.$message.error("请选择课程体系");
				}
				wechatDataFilling({...this.form,id:this.$cookies.get('wechatUserId')}).then(res=>{
					if(res.code == 0){
						this.$message.success("提交成功");
						this.$cookies.set('userToken', this.$cookies.get('wechatUserToken'));
						this.$cookies.set('userId', this.$cookies.get('wechatUserId'));
						this.$cookies.set('wechatUserToken', "");
						this.$cookies.set('wechatUserId', "");
						this.$router.replace("/");
					}else{
						return this.$message.error(res.msg);
					}
				})
			},
			tolast(){
				this.$router.push("/login");
			}
		}
	}
</script>

<style lang="scss" scoped>
	
	
	.user-info{
		padding: 35px 48px;
		box-sizing: border-box;
		.user-info-item{
			margin-bottom: 20px;
			.user-info-item-title{
				width: 70px;
				font-weight: 400;
				font-size: 16px;
				color: #777C85;
				line-height: 22px;
				text-align: right;
				margin-right: 17px;
				flex: 0 0 70px;
			}
			.user-info-item-avatar{
				text-align: center;
				img{
					width: 78px;
					height: 78px;
					border-radius: 100%;
					object-fit: cover;
				}
				div{
					font-weight: 400;
					font-size: 12px;
					color: #D1D2D4;
					line-height: 17px;
					margin-top: 10px;
				}
			}
			.user-info-item-input{
				width: 100%;
				height: 42px;
				font-weight: 400;
				// border-bottom: 1px solid #ECECEC;
			}
		}
	}
	
	.send-code{
		height: 22px;
		font-weight: 400;
		font-size: 16px;
		color: #16191E;
		line-height: 22px;
		border-left: 1px solid #E3E3E3;
		width: 120px;
		text-align: center;
		position: absolute;
		top: 50%;
		right: 0px;
		margin-top: -11px;
		cursor: pointer;
	}
	.user-info-button{
		width: 122px;
		height: 34px;
		background: #FFB80F;
		border-radius: 4px;
		font-weight: 400;
		font-size: 14px;
		color: #FFFFFF;
		line-height: 34px;
		text-align: center;
		margin: 27px auto 16px;
		cursor: pointer;
	}
	.user-info-text{
		font-weight: 400;
		font-size: 12px;
		color: #FFB80F;
		line-height: 17px;
		text-align: center;
	}
	::v-deep .user-info-item-input .el-input__inner{
		width: 100%;
		height: 42px;
		border: 0px;
		font-weight: 400;
		font-size: 16px;
		color: #16191E;
		line-height: 42px;
		text-align: left;
	}
	
	.login{
		width: 100%;
		height: 100vh;
		background-image: url(../assets/images/login/bg.png);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
	}
	.login-login{
		display: flex;
		justify-content: center;
		align-items: center;
		top: 50%;
		right: 15%;
		position: absolute;
		transform: translate(0%, -50%);
		.login-login-bg{
			position: relative;
			img{
				&:first-of-type{
					width: 55%;
					height: auto;
					margin-left: 10%;
				}
			}
		}
	}
	.login-model{
		width: 690px;
		background: #FFFFFF;
		border-radius: 20px;
		padding: 54px 0px 47px;
		.login-model-text{
			font-weight: bold;
			font-size: 24px;
			color: #16191E;
			line-height: 33px;
			text-align: center;
		}
	}
	.ruleForm{
		margin: 38px auto 0px;
		width: 370px;
		.pwd-see{
			width: 28px;
			height: 28px;
			position: absolute;
			top: 50%;
			margin-top: -14px;
			right: 18px;
			cursor: pointer;
		}
		.send-code{
			height: 22px;
			font-weight: 400;
			font-size: 16px;
			color: #16191E;
			line-height: 22px;
			border-left: 1px solid #E3E3E3;
			width: 120px;
			text-align: center;
			position: absolute;
			top: 50%;
			right: 0px;
			margin-top: -11px;
			cursor: pointer;
		}
	}
	::v-deep .el-input__inner{
		width: 370px;
		height: 50px;
		background: #F9F9F9;
		border-radius: 6px;
		border: 0px;
	}
	::v-deep .el-form-item{
		margin-bottom: 18px;
	}
	.login-button{
		font-weight: 400;
		font-size: 14px;
		color: #3C4658;
		line-height: 20px;
		margin-top: 13px;
		display: flex;
		justify-content: space-between;
		div{
			cursor: pointer;
			font-weight: 400;
			font-size: 14px;
			color: #3C4658;
			line-height: 20px;
			text-align: right;
		}
	}
	.form-button{
		margin-top: 27px;
		display: flex;
		justify-content: space-around;
		align-items: center;
		div{
			&:first-of-type{
				width: 166px;
				height: 50px;
				border-radius: 6px;
				border: 1px solid #FFB80F;
				font-weight: 400;
				font-size: 16px;
				color: #FFB80F;
				line-height: 50px;
				text-align: center;
				cursor: pointer;
			}
			&:last-of-type{
				width: 166px;
				height: 50px;
				border-radius: 6px;
				background: #FFB80F;
				border: 1px solid #FFB80F;
				font-weight: 400;
				font-size: 16px;
				color: #fff;
				line-height: 50px;
				text-align: center;
				cursor: pointer;
			}
		}
	}
	.wechat{
		text-align: center;
		margin-top: 43px;
		cursor: pointer;
		img{
			width: 55px;
			height: 55px;
		}
		div{
			font-weight: 400;
			font-size: 12px;
			color: #3C4658;
			line-height: 17px;
			margin-top: 10px;
		}
	}
	.agree{
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 23px;
		cursor: pointer;
		img{
			width: 14px;
			height: 14px;
			margin-right: 8px;
		}
		div{
			font-weight: 400;
			font-size: 12px;
			color: #3C4658;
			line-height: 17px;
			a{
				color: #FFB80F;
			}
		}
	}
</style>