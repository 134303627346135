import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
import Login from '../views/Login.vue'
import index from '../views/index.vue'
import register from '../views/register.vue'
import next from '../views/next.vue'
import forget from '../views/forget.vue'
import bindphone from '../views/bindphone.vue'
import pastpaper from '../views/pastpaper.vue'
import word from '../views/word.vue'
import question from '../views/question.vue'
import note from '../views/note.vue'
import search from '../views/search.vue'
import answer from '../views/answer.vue'
import myexam from '../views/myexam.vue'
import examinfo from '../views/examinfo.vue'
import report from '../views/report.vue'
import complate from '../views/complate.vue'
import centerindex from '../views/center/index.vue'
import centerscore from '../views/center/score.vue'
import centermemeber from '../views/center/member.vue'
import centeragree from '../views/center/agree.vue'
import centerprivacy from '../views/center/privacy.vue'
import centererrorquestion from '../views/center/errorquestion.vue'
import centerreport from '../views/center/report.vue'
import centerhsitory from '../views/center/hsitory.vue'
import hsitoryinfo from '../views/center/hsitoryinfo.vue'
import VueCookies from 'vue-cookies'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: register
  },
  {
    path: '/next',
    name: 'next',
    component: next
  },
  {
    path: '/forget',
    name: 'forget',
    component: forget
  },
  {
    path: '/complate',
    name: 'complate',
    component: complate
  },
  {
    path: '/bindphone',
    name: 'bindphone',
    component: bindphone
  },
  {
    path: '/',
    component: Layout,
    hidden: true,
    children: [
		{
			path: '/',
			name:"index",
			component: index
		},
		{
			path: '/pastpaper',
			name:"pastpaper",
			component: pastpaper
		},
		{
			path: '/word',
			name:"word",
			component: word
		},
		{
			path: '/question',
			name:"question",
			component: question
		},
		{
			path: '/note',
			name:"note",
			component: note
		},
		{
			path: '/search',
			name:"search",
			component: search
		},
		{
			path: '/answer',
			name:"answer",
			component: answer
		},
		{
			path: '/myexam',
			name:"myexam",
			component: myexam
		},
		{
			path: '/examinfo',
			name:"examinfo",
			component: examinfo
		},
		{
			path: '/report',
			name:"report",
			component: report
		},
		{
			path: '/center',
			name:"centerindex",
			component: centerindex
		},
		{
			path: '/center/score',
			name:"centerscore",
			component: centerscore
		},
		{
			path: '/center/member',
			name:"centermemeber",
			component: centermemeber
		},
		{
			path: '/center/agree',
			name:"centeragree",
			component: centeragree
		},
		{
			path: '/center/privacy',
			name:"centerprivacy",
			component: centerprivacy
		},
		{
			path: '/center/errorquestion',
			name:"centererrorquestion",
			component: centererrorquestion
		},
		{
			path: '/center/report',
			name:"centerreport",
			component: centerreport
		},
		{
			path: '/center/hsitory',
			name:"centerhsitory",
			component: centerhsitory
		},
		{
			path: '/center/hsitoryinfo',
			name:"hsitoryinfo",
			component: hsitoryinfo
		},
		
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach(async (to, from, next) => {
	if(to.name == "login" || to.name == "forget" || to.name == "register"){
		next();
	}else if(to.name == "complate"){
		const token = VueCookies.get('wechatUserToken');
		if (token && token !== 'undefined') {
			next()
		} else {
			// 没有登录的时候跳转到登录界面
			// 携带上登陆成功之后需要跳转的页面完整路径
			next({
				name: 'login'
			})
		}
	}else{
		const token = VueCookies.get('userToken');
		if (token && token !== 'undefined') {
			next()
		} else {
			// 没有登录的时候跳转到登录界面
			// 携带上登陆成功之后需要跳转的页面完整路径
			next({
				name: 'login'
			})
		}
	}
})

export default router
