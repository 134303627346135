<template>
	<div class="usercneter">
		<infoTop></infoTop>
		<div class="center-content flex-start">
			<centerLeft :menu="3"></centerLeft>
			<div class="score-info">
				<div class="score-info-img">
					<img class="score-info-img" src="@/assets/images/me/scorebg.png"/>
					<div class="score-info-num">
						<div>{{value.total}}</div>
						<div>我的积分</div>
					</div>
					<div class="score-info-rule flex-center flex-midden" @click="showRuleInfo">
						<img src="@/assets/images/me/scoreicon.png"/>
						<div>规则</div>
					</div>
				</div>
				<!-- <img class="" src="@/assets/images/me/score.png"/> -->
				<div class="score-info-title">做任务得积分</div>
				<div class="score-list">
					<div v-for="item in list" :key="item.id" class="score-item flex-between flex-center">
						<div class="score-item-left">
						  <div v-if="item.ruleKey == 'improve_information'">{{item.name}}({{value.info}}/{{item.maxTimes}})</div>
						  <div v-else-if="item.ruleKey == 'video_comments'">{{item.name}}({{value.comment}}/{{item.maxTimes}})</div>
						  <div v-if="item.ruleKey == 'register'">{{item.name}}({{value.login}}/{{item.maxTimes}})</div>
						  <div v-if="item.ruleKey == 'daily_message'">{{item.name}}({{value.dailyMessage}}/{{item.maxTimes}})</div>
						  <div v-if="item.ruleKey == 'sign_in'">{{item.name}}(1/{{item.maxTimes}})</div>
						  <div v-if="item.ruleKey == 'invite_friends'">{{item.name}}({{value.share}}/{{item.maxTimes}})</div>
						  <div v-if="item.ruleKey == 'join_community'">{{item.name}}({{value.joingroup}}/{{item.maxTimes}})</div>
							<div>+{{item.points}}积分</div>
						</div>
						<template v-if="item.ruleKey == 'improve_information'">
						  <div v-if="value.info == item.maxTimes" class="score-item-right-three">已完成</div>
						  <router-link hover-class="none" to="/center" v-else  class="score-item-right-two">去完成</router-link>
						</template>
						<template v-if="item.ruleKey == 'join_community'">
						  <div v-if="value.joingroup == item.maxTimes" class="score-item-right-three">已完成</div>
						  <div v-else  class="score-item-right-two" @click="showwechat">去完成</div>
						</template>
						<div class="score-item-right-one" v-if="item.ruleKey == 'video_comments' || item.ruleKey == 'daily_message' || item.ruleKey == 'invite_friends'" @click="showma=true">小程序中完成</div>
						<div v-if="item.ruleKey == 'register' || item.ruleKey == 'sign_in'" class="score-item-right-three">已完成</div>
						
					</div>
				</div>
			</div>
		</div>
		
		<el-dialog title="" :visible.sync="dialogVisible" width="595px" custom-class="addModel" :center="true" :append-to-body="true" :show-close="true" :close-on-click-modal="false">
		 <div class="aggree">
			 <!-- <div class="agree-title">{{type == 1?'隐私政策':'用户协议'}}</div> -->
			 <div class="agree-content" v-html="content"></div>
		 </div>
		</el-dialog>
		<el-dialog title="" :visible.sync="showma" width="300px" custom-class="addModel" :center="true" :append-to-body="true" :show-close="true" :close-on-click-modal="false">
		 <div class="aggree">
			 <img src="@/assets/images/ma.jpg" width="100%"/>
		 </div>
		</el-dialog>
		<wechatcode ref="wechatcode"></wechatcode>
	</div>
</template>

<script>
	import infoTop from "@/components/info-top.vue"
	import centerLeft from "@/components/center-left.vue"
	import {points} from "@/api/me"
	import {protocol} from "@/api/index"
	import wechatcode from "@/components/wechatcode.vue"
	export default {
		components:{
			infoTop,
			centerLeft,
			wechatcode
		},
		data(){
			return {
				list:[],
				value:{
				  login:0,
				  total:0,
				  dailyMessage:0,
				  comment:0,
				  share:0,
				  info:0
				},
				dialogVisible:false,
				content:"",
				showma:false
			}
		},
		mounted() {
			points().then(res=>{
				this.list = res.rules;
				this.value = res;
			})
		},
		methods:{
			showRuleInfo(){
				protocol({type:8}).then(res=>{
					if(res.code == 0){
						this.content = res.data.content;
						this.dialogVisible = true;
					}else{
						this.$message.error(res.msg);
					}
					
				})
			},
			showwechat(){
				this.$refs.wechatcode.init();
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "./index.scss";
	
	.aggree{
		.agree-content{
			font-weight: 400;
			font-size: 14px;
			color: #3B3B3B;
			line-height: 20px;
			padding: 20px 24px;
			box-sizing: border-box;
			height: 40vh;
			overflow: auto;
			
		}
	}
	::v-deep .addModel{
		border-radius: 10px !important;
	}
</style>